import React from 'react';
import PropTypes from 'prop-types';

import Content from 'atoms/content/Content';
import Image from 'atoms/image/Image';
import Link from 'atoms/link/Link';

import './LogoItem.scss';

const defaultProps = {
    className: '',
    content: [],
    image: {},
    link: {},
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.array,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    link: PropTypes.shape({
        url: PropTypes.object,
    }),
};

function LogoItem({
    className,
    content,
    image,
    link,
}) {
    const imageAlt = (image?.alt) ? image?.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;
    const linkLabel = (image?.alt) ? image?.alt : null;
    const linkUrl = link?.url;
    const renderLink = linkUrl?.link_type !== 'Any';

    return (
        <>
            <div className={`m-logo-item ${className}`}>
                {imageUrl && !renderLink && (
                    <Image
                        className="m-logo-item__image"
                        image={{
                            alt: imageAlt,
                            dimensions: {
                                height: imageHeight,
                                width: imageWidth,
                            },
                            url: imageUrl,
                        }}
                        size='small'
                    />
                )}
                {imageUrl && renderLink && (
                    <Link aria-label={linkLabel} className="m-logo-item__image-link" to={linkUrl}>
                        <Image
                            className="m-logo-item__image"
                            image={{
                                alt: imageAlt,
                                dimensions: {
                                    height: imageHeight,
                                    width: imageWidth,
                                },
                                url: imageUrl,
                            }}
                            size='small'
                        />
                    </Link>
                )}
                {(content[0]?.text) && (
                    <Content className="m-logo-item__content" content={content} />
                )}
            </div>
        </>
    );
}

LogoItem.propTypes = propTypes;
LogoItem.defaultProps = defaultProps;

export default LogoItem;