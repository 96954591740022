import React from 'react';
import Accordion from 'organisms/accordion/Accordion';
import Address from 'organisms/address/Address';
import AutoplayVideo from 'organisms/autoplayvideo/AutoplayVideo';
import Button from 'organisms/button/Button';
import Content from 'organisms/content/Content';
import Image from 'organisms/image/Image';
import JobListing from 'organisms/joblisting/JobListing';
import LinkListing from 'organisms/linklisting/LinkListing';
import List from 'organisms/list/List';
import LogoListing from 'organisms/logolisting/LogoListing';
import SectionHeader from 'organisms/sectionheader/SectionHeader';
import TeamListing from 'organisms/teamlisting/TeamListing';
import Video from 'organisms/video/Video';

let index = 0;

function renderComponents(component, type) {
    switch (type) {
    case 'accordion':
        index++;
        return (
            <Accordion
                key={`${type}-${index}`}
                listing={component?.items}
                multiple={component?.primary?.ac_multiple}
            />
        );
    case 'address':
        index++;
        return (
            <Address
                key={`${type}-${index}`}
                address={component?.primary?.ad_address?.raw?.[0]?.text}
                image={component?.primary?.ad_map_image}
                imageUrl={component?.primary?.ad_map_link}
                name={component?.primary?.ad_name?.raw?.[0]?.text}
                nameUrl={component?.primary?.ad_name_url}
            />
        );
    case 'autoplay_video':
        index++;
        return (
            <AutoplayVideo
                key={`${type}-${index}`}
                size={component?.primary?.av_size}
                videoUpload={{
                    url: component?.primary?.av_video?.url,
                }}
            />
        );
    case 'button':
        index++;
        return (
            <Button
                key={`${type}-${index}`}
                buttonStyle={'Secondary'}
                link={component?.primary?.bu_url}
                linkText={component?.primary?.bu_text?.raw?.[0]?.text}
            />
        );
    case 'content':
        index++;
        return (
            <Content
                key={`${type}-${index}`}
                content={component?.primary?.co_content?.raw}
            />
        );
    case 'image':
        index++;
        return (
            <Image
                key={`${type}-${index}`}
                caption={component?.primary?.im_caption?.raw}
                image={{
                    alt: component?.primary?.im_image?.alt,
                    dimensions: {
                        height: component?.primary?.im_image?.dimensions?.height,
                        width: component?.primary?.im_image?.dimensions?.width,
                    },
                    url: component?.primary?.im_image?.url,
                }}
                size={component?.primary?.im_size}
            />
        );
    case 'job_listing':
        index++;
        return (
            <JobListing
                key={`${type}-${index}`}
            />
        );
    case 'link_listing':
        index++;
        return (
            <LinkListing
                key={`${type}-${index}`}
                listing={component?.items}
            />
        );
    case 'list':
        index++;
        return (
            <List
                key={`${type}-${index}`}
                listing={component?.items}
            />
        );
    case 'logo_listing':
        index++;
        return (
            <LogoListing
                key={`${type}-${index}`}
                listing={component?.items}
            />
        );
    case 'section_header':
        index++;
        return (
            <SectionHeader
                key={`${type}-${index}`}
                heading={component?.primary?.sh_heading?.raw?.[0]?.text}
                size={component?.primary?.sh_size}
            />
        );
    case 'team_listing':
        index++;
        return (
            <TeamListing
                key={`${type}-${index}`}
                listing={component?.items}
                multiple={component?.primary?.tl_multiple}
            />
        );
    case 'video':
        index++;
        const imageSize = (function(size) {
            switch(size) {
                case 'Normal':
                    return '&w=960';
                case 'Wide':
                    return '&w=1280';
                case 'Full Width':
                    return '&w=1920';
                default:
                    return '';
            }
        })(component?.primary?.vi_size);

        return (
            <Video
                key={`${type}-${index}`}
                caption={component?.primary?.vi_caption?.raw}
                size={component?.primary?.vi_size}
                videoEmbed={{
                    image: (component?.primary?.vi_image?.url) ? `${component?.primary?.vi_image?.url}${imageSize}` : component?.primary?.vi_video?.thumbnail_url,
                    html: component?.primary?.vi_video?.html,
                    providerName: component?.primary?.vi_video?.provider_name,
                    title: component?.primary?.vi_video?.title,
                }}
            />
        );
    default:
        return null;
    }
}

export { renderComponents };
