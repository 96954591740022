import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import './SectionHeader.scss';

const defaultProps = {
    className: '',
    description: '',
    size: 'Normal',
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    size: PropTypes.string,
};

function SectionHeader({
    className,
    heading,
    size,
}) {
    const sizeClassName = (size === 'Small') ? 'm-section-header--small' : '';
    const classNames = cx('m-section-header', sizeClassName, className);

    return (
        <>
            <header className={classNames}>
                {heading && size === 'Normal' && (
                    <h2 className="m-section-header__heading">{heading}</h2>
                )}
                {heading && size === 'Small' && (
                    <h3 className="m-section-header__heading">{heading}</h3>
                )}
            </header>
        </>
    );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;