import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import { default as SectionHeaderMolecule } from 'molecules/sectionheader/SectionHeader';

import './SectionHeader.scss';

const defaultProps = {
    button: {},
    className: '',
    description: '',
    heading: '',
};

const propTypes = {
    button: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    description: PropTypes.string,
    heading: PropTypes.string,
    size: PropTypes.string,
};

function SectionHeader({
    button,
    className,
    description,
    heading,
    size,
}) {
    const sizeClassName = (size === 'Small') ? 'o-section-header--small' : '';
    const classNames = cx('o-section-header', sizeClassName, className);

    return (
        <>
            <div className={classNames}>
                <div className="l-grid l-grid--container">
                    <div className="o-section-header__container">
                        <SectionHeaderMolecule
                            button={button}
                            className="o-section-header__header"
                            description={description}
                            heading={heading}
                            size={size}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;