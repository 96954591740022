import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconInfo({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
                <path d="M12 16V12" />
                <circle cx="12" cy="8" r="1" fill="currentColor" stroke="none" />
            </svg>
        </>
    );
}

IconInfo.propTypes = propTypes;
IconInfo.defaultProps = defaultProps;

export default IconInfo;
