import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import IconClose from 'atoms/icon/Close';
import IconInfo from 'atoms/icon/Info';
import IconTick from 'atoms/icon/Tick';
import IconWarning from 'atoms/icon/Warning';

import './Message.scss';

const defaultProps = {
    className: '',
    compact: false,
    heading: '',
    type: 'info',
};

const propTypes = {
    className: PropTypes.string,
    compact: PropTypes.bool,
    heading: PropTypes.string,
    html: PropTypes.string.isRequired,
    type: PropTypes.string,
};

function Message({
    className,
    compact,
    heading,
    html,
    type,
}) {
    const compactClassName = (compact) ? 'a-message--compact' : '';
    const classNames = cx('a-message', `a-message--${type}`, compactClassName, className);

    return (
        <>
            <div className={classNames} role="alert">
                {!compact && (
                    <div className="a-message__icon-container">
                        {type === 'error' && (
                            <IconClose className="a-message__icon" />
                        )}
                        {type === 'info' && (
                            <IconInfo className="a-message__icon" />
                        )}
                        {type === 'success' && (
                            <IconTick className="a-message__icon" />
                        )}
                        {type === 'warning' && (
                            <IconWarning className="a-message__icon" />
                        )}
                    </div>
                )}
                <div className="a-message__content-container">
                    {heading && (
                        <strong className="a-message__heading">{heading}</strong>
                    )}
                    <div className="a-message__content" dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
        </>
    );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default Message;