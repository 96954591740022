import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconCopy({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 512 512">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M320 426.7H106.7C47.8 426.6.1 378.9 0 320V106.7C.1 47.8 47.8.1 106.7 0H320c58.9.1 106.6 47.8 106.7 106.7V320c-.1 58.9-47.8 106.6-106.7 106.7zm-213.3-384c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V106.7c0-35.3-28.7-64-64-64H106.7zM512 405.3V128c0-11.8-9.6-21.3-21.3-21.3-11.8 0-21.3 9.6-21.3 21.3v277.3c0 35.3-28.7 64-64 64H128c-11.8 0-21.3 9.6-21.3 21.3 0 11.8 9.6 21.3 21.3 21.3h277.3c58.9 0 106.6-47.7 106.7-106.6z"/>
            </svg>
        </>
    );
}

IconCopy.propTypes = propTypes;
IconCopy.defaultProps = defaultProps;

export default IconCopy;
