import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { default as cx } from 'clsx';

import VideoAtom from 'atoms/video/Video';

import './AutoplayVideo.scss';

const defaultProps = {
    className: '',
    size: 'Normal',
    videoUpload: {}
};

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    videoUpload: PropTypes.shape({
        url: PropTypes.string,
    }),
};

function AutoplayVideo({
    className,
    size,
    videoUpload,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.75,
    });
    const sizeClassName = (function(size) {
        switch(size) {
            case 'Normal':
                return '';
            case 'Wide':
                return 'o-autoplay-video--wide';
            case 'Full Width':
                return 'o-autoplay-video--xwide';
            default:
                return '';
        }
    })(size);
    const classNames = cx('o-autoplay-video', sizeClassName, className);
    const videoUploadUrl = videoUpload?.url;
    const videoRef = useRef(null);
    
    if (videoRef.current) {
        if (entry && !inView) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    }

    return (
        <>
            <div className={classNames}>
                <div className="l-grid l-grid--container">
                    <figure className="o-autoplay-video__container">
                        {videoUploadUrl && (
                            <div className="o-autoplay-video__wrapper" ref={ref}>
                                <VideoAtom
                                    autoPlay={false}
                                    className="o-autoplay-video__video"
                                    loop={false}
                                    ref={videoRef}
                                    videoUpload={videoUpload}
                                />
                            </div>
                        )}
                    </figure>
                </div>
            </div>
        </>
    );
}

AutoplayVideo.propTypes = propTypes;
AutoplayVideo.defaultProps = defaultProps;

export default AutoplayVideo;