import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconPin({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10V10Z" />
                <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" />
            </svg>
        </>
    );
}

IconPin.propTypes = propTypes;
IconPin.defaultProps = defaultProps;

export default IconPin;
