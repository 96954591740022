import React from 'react';
import PropTypes from 'prop-types';

import './ListItem.scss';

const defaultProps = {
    className: '',
    text: '',
};

const propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
};

function ListItem({
    className,
    text,
}) {
    return (
        <>
            <li className={`m-list-item ${className}`}>
                {text}
            </li>
        </>
    );
}

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;