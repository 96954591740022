import React from 'react';
import PropTypes from 'prop-types';

import LogoItem from 'molecules/logoitem/LogoItem';

import './LogoListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function LogoListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-logo-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-logo-listing__container">
                        {listing.map(({ ll_content, ll_image, ll_url }, index) => {
                            const content = ll_content?.raw;
                            const image = {
                                alt: (ll_image?.alt) ? ll_image.alt : '',
                                dimensions: {
                                    height: ll_image?.dimensions?.height,
                                    width: ll_image?.dimensions?.width,
                                },
                                url: ll_image?.url,
                            }
                            const link = {
                                url: ll_url,
                            }

                            return (
                                <LogoItem
                                    className="o-logo-listing__item"
                                    content={content}
                                    image={image}
                                    key={`{${index}}-logo-item`}
                                    link={link}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

LogoListing.propTypes = propTypes;
LogoListing.defaultProps = defaultProps;

export default LogoListing;