import React from 'react';
import PropTypes from 'prop-types';

import IconPin from 'atoms/icon/Pin';
import Image from 'atoms/image/Image';
import Link from 'atoms/link/Link';

import './Address.scss';

const defaultProps = {
    address: '',
    className: '',
    image: {},
    imageUrl: [],
    name: '',
    nameUrl: {},
};

const propTypes = {
    address: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
    imageUrl: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    nameUrl: PropTypes.object,
};

function Address({
    address,
    className,
    image,
    imageUrl,
    name,
    nameUrl,
}) {
    const imageHeight = image?.dimensions?.height;
    const imageSrc = image?.url;
    const imageWidth = image?.dimensions?.width;

    return (
        <>
            <div className={`o-address ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-address__container">
                        <div className="o-address__address-container">
                            {address && (
                                <address className="o-address__address">{address}</address>
                            )}
                            {name && nameUrl && (
                                <div className="o-address__name-container">
                                    <Link className="o-address__name" to={nameUrl}>
                                        <span className="o-address__name-slashes">///</span>
                                        {name}
                                    </Link>
                                </div>
                            )}
                        </div>
                        {imageSrc && (
                            <div className="o-address__map-container">
                                {!imageUrl && (
                                    <Image
                                        className="o-address__map"
                                        image={{
                                            dimensions: {
                                                height: imageHeight,
                                                width: imageWidth,
                                            },
                                            url: imageSrc,
                                        }}
                                        size='xsmall'
                                    />
                                )}
                                {imageUrl && (
                                    <Link className="o-address__map-link" to={imageUrl}>
                                        <Image
                                            className="o-address__map"
                                            image={{
                                                dimensions: {
                                                    height: imageHeight,
                                                    width: imageWidth,
                                                },
                                                url: imageSrc,
                                            }}
                                            size='xsmall'
                                        />
                                    </Link>
                                )}
                                <IconPin className="o-address__map-icon" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Address.propTypes = propTypes;
Address.defaultProps = defaultProps;

export default Address;