import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IconCopy from 'atoms/icon/Copy';
import IconFacebook from 'atoms/icon/Facebook';
import IconLinkedIn from 'atoms/icon/LinkedIn';
import IconPhone from 'atoms/icon/Phone';
import IconTwitter from 'atoms/icon/Twitter';
import Link from 'atoms/link/Link';
import Message from 'atoms/message/Message';

import './LinkItem.scss';

const defaultProps = {
    className: '',
    copyUrl: false,
    icon: '',
    link: {},
};

const propTypes = {
    className: PropTypes.string,
    copyUrl: PropTypes.bool,
    icon: PropTypes.string,
    link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.object,
    }),
};

function LinkItem({
    className,
    copyUrl,
    icon,
    link,
}) {
    const [state, setState] = useState({
        messageOpen: false,
        messageText: '',
        messageType: null,
    });
    const linkText = link?.text;
    const linkUrl = link?.url;

    function renderIcon(name) {
        switch(name) {
            case 'Facebook':
                return <IconFacebook />;
            case 'Link':
                return <IconCopy />;
            case 'LinkedIn':
                return <IconLinkedIn />;
            case 'Phone':
                return <IconPhone />;
            case 'Twitter':
                return <IconTwitter />;
            default:
                return '';
        }
    }

    function copyUrlToClipboard(event) {
        if (copyUrl) {
            event.preventDefault();

            let href = event.target.getAttribute('href');
            href = href.substring(href.indexOf(':') + 1);

            navigator.clipboard.writeText(href).then(() => {
                setState((previousState) => {
                    return {
                        ...previousState,
                        messageOpen: true,
                        messageText: 'Link successfully copied to clipboard!',
                        messageType: 'success',
                    }
                });
            }, () => {
                setState((previousState) => {
                    return {
                        ...previousState,
                        messageOpen: true,
                        messageText: 'Oh no, something went wrong, please try again!',
                        messageType: 'error',
                    }
                });
            });
        }
    }

    useEffect(function() {
        setTimeout(function() {
            setState((previousState) => {
                return {
                    ...previousState,
                    messageOpen: false,
                }
            });
        }, 1200);
    }, [state.messageOpen]);

    return (
        <>
            <div className={`m-link-item ${className}`}>
                <Link className="m-link-item__link" onClick={copyUrlToClipboard} to={linkUrl}>
                    {linkText && (
                        <span className="m-link-item__text-container">
                            <span className="m-link-item__text">{linkText}</span>
                        </span>
                    )}
                    {icon && (
                        <div className="m-link-item__icon-container">
                            {renderIcon(icon)}
                        </div>
                    )}
                </Link>
                {state.messageOpen && state.messageText && (
                    <div className="m-link-item__message-container">
                        <Message compact={true} className="m-link-item__message" html={`<p>${state.messageText}</p>`} type={state.messageType} />
                    </div>
                )}
            </div>
        </>
    );
}

LinkItem.propTypes = propTypes;
LinkItem.defaultProps = defaultProps;

export default LinkItem;