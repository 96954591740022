import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconTwitter({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 512 512">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M459.6 151.6c.3 4.5.3 9 .3 13.6C459.9 303.9 354.2 464 161 464v-.1c-57.1.1-113-16.2-161-47.1 8.3 1 16.6 1.5 25 1.5 47.3 0 93.3-15.8 130.5-45.1-44.9-.9-84.4-30.2-98.1-73 15.7 3 32 2.4 47.4-1.8-49-9.9-84.3-53-84.3-103v-1.3c14.6 8.1 31 12.7 47.7 13.1-46.2-30.6-60.5-92-32.6-140 53.3 65.6 132 105.5 216.5 109.8-8.5-36.5 3.1-74.7 30.4-100.4 42.3-39.8 108.8-37.7 148.6 4.6 23.5-4.6 46.1-13.3 66.7-25.5-7.9 24.3-24.3 45-46.2 58.1 20.8-2.5 41.2-8.1 60.4-16.6-14.1 21.1-31.9 39.5-52.4 54.4z"/>
            </svg>
        </>
    );
}

IconTwitter.propTypes = propTypes;
IconTwitter.defaultProps = defaultProps;

export default IconTwitter;
