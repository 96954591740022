import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
    Accordion as AccordionPlugin,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useAccordionItemContext,
} from '@reach/accordion';
import "@reach/accordion/styles.css";

import Button from 'organisms/button/Button';
import IconPlus from 'atoms/icon/Plus';
import Link from 'atoms/link/Link';

import './JobListing.scss';

const defaultProps = {
    className: '',
    multiple: false,
};

const propTypes = {
    className: PropTypes.string,
    multiple: PropTypes.bool,
};

function JobListing({
    className,
    multiple,
}) {
    const [jobs, setJobs] = useState([]);
    const [renderFallback, setRenderFallback] = useState(true);

    useEffect(function() {
        Axios.get(process.env.GATSBY_WORKABLE_ENDPOINT)
        .then(response => {
            const data = response.data;
            setJobs(data.jobs);
            setRenderFallback(false);
        })
        .catch(err => {
            console.log(`Something bad happened while fetching the data\n${err}`);
        });
    }, []);

    return (
        <>
            {renderFallback && (
                <Button link={{ link_type: 'Web', url: 'https://apply.workable.com/klarian-limited/' }} linkText="VIEW CURRENT OPENINGS" />
            )}
            {!renderFallback && (
                <AccordionPlugin className={`o-accordion ${className}`} collapsible multiple={multiple}>
                    <div className="l-grid l-grid--container">
                        <div className="o-accordion__container">
                            {jobs.map((data, index) => {
                                return (
                                    <AccordionItem className="o-accordion__item" key={`${index}-accordion-item`}> 
                                        <JobListingItemChildren data={data} heading={data.title} />
                                    </AccordionItem>
                                );
                            })}
                        </div>
                    </div>
                </AccordionPlugin>
            )}
        </>
    );
}

function JobListingItemChildren({
    data,
    heading,
}) {
    const { index, isExpanded } = useAccordionItemContext();
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const panelHeightStyle = {
        height: isExpanded ? height : 0,
    };

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <>
            {heading && (
                <h3 className="o-accordion__item-title">
                    <AccordionButton className="o-accordion__item-button">
                        {heading}
                        <div className="o-accordion__item-button-icon-container">
                            <IconPlus className="o-accordion__item-button-icon" />
                        </div>
                    </AccordionButton>
                </h3>
            )}
            <AccordionPanel className="o-accordion__item-panel" style={panelHeightStyle}>
                <div className="o-accordion__item-panel-container" ref={ref}>
                    <div className="o-accordion__item-meta a-content">
                        <dl className="o-accordion__item-meta-list">
                            {data.department && (
                                <div className="o-accordion__item-meta-item">
                                    <dt>Department:</dt>
                                    <dd>{data.department}</dd>
                                </div>
                            )}
                            {(data.city || data.state || data.country) && (
                                <div className="o-accordion__item-meta-item">
                                    <dt>Location:</dt>
                                    <dd>
                                        {data.city && <>{data.city}, </>}
                                        {data.state && <>{data.state}, </>}
                                        {data.country && data.country}
                                    </dd>
                                </div>
                            )}
                            {data.employment_type && (
                                <div className="o-accordion__item-meta-item">
                                    <dt>Employment type:</dt>
                                    <dd>{data.employment_type}</dd>
                                </div>
                            )}
                            {data.created_at && (
                                <div className="o-accordion__item-meta-item">
                                    <dt>Created:</dt>
                                    <dd>{new Date(data.created_at).toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric' })}</dd>
                                </div>
                            )}
                        </dl>
                        <Link to={{ link_type: 'Web', url: data.application_url }}>Apply for {data.title.toLowerCase()} role</Link>
                    </div>

                    <div className="o-accordion__content a-content">
                        <div className="a-content" dangerouslySetInnerHTML={{ __html: data.description }} />
                        <Link className="o-accordion__content-link" to={{ link_type: 'Web', url: data.application_url }}>Apply for {data.title.toLowerCase()} role</Link>
                    </div>
                </div>
            </AccordionPanel>
        </>
    )
}

JobListing.propTypes = propTypes;
JobListing.defaultProps = defaultProps;

export default JobListing;