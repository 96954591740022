import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconPhone({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 512 512">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M277.3 21.3c0-11.8 9.6-21.3 21.3-21.3C416.4.1 511.9 95.6 512 213.3c0 11.8-9.6 21.3-21.3 21.3-11.8 0-21.3-9.6-21.3-21.3-.1-94.2-76.5-170.6-170.7-170.7-11.8.1-21.4-9.5-21.4-21.3zM298.7 128c47.1 0 85.3 38.2 85.3 85.3 0 11.8 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3c-.1-70.7-57.3-127.9-128-128-11.8 0-21.3 9.6-21.3 21.3s9.6 21.4 21.4 21.4zm194 229.1c25.7 25.8 25.7 67.6 0 93.4l-19.4 22.4C298.5 640.1-126.7 215.1 38 39.8l24.5-21.3c25.8-25 67-24.6 92.3.9.7.7 40.2 52 40.2 52 24.4 25.6 24.3 65.8-.1 91.3l-24.7 31.1C197.8 260.9 251 314.2 318.1 342l31.3-24.9c25.5-24.4 65.7-24.5 91.3-.1 0-.1 51.3 39.4 52 40.1zm-29.4 31s-51.1-39.3-51.7-39.9c-9.1-9.1-23.9-9.1-33 0-.6.6-43.6 34.9-43.6 34.9-5.9 4.7-13.8 5.9-20.9 3.2-87-32.4-155.7-101-188.2-187.9-2.9-7.1-1.7-15.3 3.1-21.3 0 0 34.3-43.1 34.9-43.6 9.1-9.1 9.1-23.9 0-33-.7-.6-39.9-51.7-39.9-51.7-9.3-8.3-23.4-7.9-32.2.8L67.1 70.8C-53.2 215.6 315.2 563.6 442 443.7l19.4-22.4c9.6-8.7 10.4-23.4 1.9-33.2z"/>
            </svg>
        </>
    );
}

IconPhone.propTypes = propTypes;
IconPhone.defaultProps = defaultProps;

export default IconPhone;
