import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion as AccordionPlugin,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useAccordionItemContext,
} from '@reach/accordion';
import "@reach/accordion/styles.css";

import Content from 'atoms/content/Content';
import IconPlus from 'atoms/icon/Plus';

import './Accordion.scss';

const defaultProps = {
    className: '',
    listing: [],
    multiple: false,
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    multiple: PropTypes.bool,
};

function Accordion({
    className,
    listing,
    multiple,
}) {
    return (
        <>
            <AccordionPlugin className={`o-accordion ${className}`} collapsible multiple={multiple}>
                <div className="l-grid l-grid--container">
                    <div className="o-accordion__container">
                        {listing.map(({ ac_content, ac_heading }, index) => {
                            const content = ac_content?.raw;
                            const heading = ac_heading?.raw?.[0]?.text;

                            return (
                                <AccordionItem className="o-accordion__item" key={`${index}-accordion-item`}> 
                                     <AccordionItemChildren content={content} heading={heading} />
                                </AccordionItem>
                            );
                        })}
                    </div>
                </div>
            </AccordionPlugin>
        </>
    );
}

function AccordionItemChildren({
    content,
    heading,
}) {
    const { index, isExpanded } = useAccordionItemContext();
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const panelHeightStyle = {
        height: isExpanded ? height : 0,
    };

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <>
            {heading && (
                <h3 className="o-accordion__item-title">
                    <AccordionButton className="o-accordion__item-button">
                        {heading}
                        <div className="o-accordion__item-button-icon-container">
                            <IconPlus className="o-accordion__item-button-icon" />
                        </div>
                    </AccordionButton>
                </h3>
            )}
            {content && (
                <AccordionPanel className="o-accordion__item-panel" style={panelHeightStyle}>
                    <div className="o-accordion__item-panel-container" ref={ref}>
                        <Content className="o-accordion__item-content" content={content} />
                    </div>
                </AccordionPanel>
            )}
        </>
    )
}

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;