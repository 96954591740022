import React from 'react';
import PropTypes from 'prop-types';

import ListItem from 'molecules/listitem/ListItem';

import './List.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function List({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-list ${className}`}>
                <div className="l-grid l-grid--container">
                    <ul className="o-list__container">
                        {listing.map(({ li_text }, index) => {
                            const text = li_text?.raw?.[0]?.text;
                            
                            return (
                                <ListItem
                                    className="o-list__item"
                                    key={`{${index}}-list-item`}
                                    text={text}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;