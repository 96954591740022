import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion as AccordionPlugin,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useAccordionItemContext,
} from '@reach/accordion';
import "@reach/accordion/styles.css";

import Content from 'atoms/content/Content';
import IconLinkedIn from 'atoms/icon/LinkedIn';
import IconPlus from 'atoms/icon/Plus';
import Image from 'atoms/image/Image';
import Link from 'atoms/link/Link';

import './TeamListing.scss';

const defaultProps = {
    className: '',
    listing: [],
    multiple: false,
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    multiple: PropTypes.bool,
};

function TeamListing({
    className,
    listing,
    multiple,
}) {
    return (
        <>
            <AccordionPlugin className={`o-team-listing ${className}`} collapsible multiple={multiple}>
                <div className="l-grid l-grid--container">
                    <div className="o-team-listing__container">
                        {listing.map(({ tl_team_member }, index) => {
                            const doc = tl_team_member?.document?.data;
                            const bio = doc?.te_bio?.raw;
                            const image = {
                                alt: (doc?.te_image?.alt) ? doc?.te_image.alt : '',
                                dimensions: {
                                    height: doc?.te_image?.dimensions?.height,
                                    width: doc?.te_image?.dimensions?.width,
                                },
                                url: doc?.te_image?.url,
                            }
                            const jobTitle = doc?.te_job_title?.raw?.[0]?.text;
                            const linkedInLinkText = doc?.te_linkedin_text?.raw?.[0]?.text;
                            const linkedInLinkUrl = doc?.te_linkedin_url;
                            const name = doc?.te_name?.raw?.[0]?.text;

                            return (
                                name && (
                                    <AccordionItem className="o-team-listing__item" key={`${index}-team-item`}>
                                        <TeamListingAccordionItemChildren bio={bio} image={image} jobTitle={jobTitle} linkedInLinkText={linkedInLinkText} linkedInLinkUrl={linkedInLinkUrl} name={name} />
                                    </AccordionItem>
                                )
                            );
                        })}
                    </div>
                </div>
            </AccordionPlugin>
        </>
    );
}

function TeamListingAccordionItemChildren({
    bio,
    image,
    jobTitle,
    linkedInLinkText,
    linkedInLinkUrl,
    name,
}) {
    const { index, isExpanded } = useAccordionItemContext();
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const panelHeightStyle = {
        height: isExpanded ? height : 0,
    };

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <>
            {name && (
                <h3 className="o-team-listing__item-title">
                    <AccordionButton className="o-team-listing__item-button">
                        <div className="o-team-listing__item-avatar-container">
                            <div className="o-team-listing__item-avatar-wrapper">
                                {image?.url && (
                                    <Image
                                        className="o-team-listing__item-avatar"
                                        image={image}
                                        size={192}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="o-team-listing__item-button-container">
                            {name}
                            {jobTitle && (
                                <>, {jobTitle}</>
                            )}
                            <div className="o-team-listing__item-button-icon-container">
                                <IconPlus className="o-team-listing__item-button-icon" />
                            </div>
                        </div>
                    </AccordionButton>
                </h3>
            )}
            {bio && (
                <AccordionPanel className="o-team-listing__item-panel" style={panelHeightStyle}>
                    <div className="o-team-listing__item-panel-container" ref={ref}>
                        <Content className="o-team-listing__item-content" content={bio} />
                        {(linkedInLinkUrl && linkedInLinkText) && (
                            <Link className="o-team-listing__item-linkedin-link" to={linkedInLinkUrl}>
                                <span className="o-team-listing__item-linkedin-link-text-container">
                                    <span className="o-team-listing__item-linkedin-link-text">{linkedInLinkText}</span>
                                </span>
                                <div className="o-team-listing__item-linkedin-link-icon-container">
                                    <IconLinkedIn />
                                </div>
                            </Link>
                        )}
                    </div>
                </AccordionPanel>
            )}
        </>
    )
}

TeamListing.propTypes = propTypes;
TeamListing.defaultProps = defaultProps;

export default TeamListing;
