import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { renderComponents } from 'utility/renderContentComponents';
import Shell from 'layout/shell/Shell';
import Masthead from 'organisms/masthead/Masthead';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function ContentPage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicContent?.data;
    const pageDescription = doc?.page_meta_description?.raw?.[0]?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.raw?.[0]?.text;
    const components = doc?.body;
    const mastheadBackgroundImageAltText = doc?.ma_background_image?.alt;
    const mastheadBackgroundImageHeight = doc?.ma_background_image?.dimensions?.height;
    const mastheadBackgroundImageUrl = doc?.ma_background_image?.url;
    const mastheadBackgroundImageWidth = doc?.ma_background_image?.dimensions?.width;
    const mastheadBackgroundVideoUrl = doc?.ma_background_video?.url;
    const mastheadDescription = doc?.ma_description?.raw?.[0]?.text;
    const mastheadHeading = doc?.ma_heading?.raw?.[0]?.text;

    return (
        <Shell
            className="t-content"
            pathname={pathname}
            seoDescription={pageDescription}
            seoImage={pageSocialImage}
            seoTitle={pageTitle}
        >
            <main className="l-grid__main t-content__main" id="main-content">
                <Masthead
                    description={mastheadDescription}
                    heading={mastheadHeading}
                    image={{
                        alt: mastheadBackgroundImageAltText,
                        dimensions: {
                            height: mastheadBackgroundImageHeight,
                            width: mastheadBackgroundImageWidth,
                        },
                        url: mastheadBackgroundImageUrl,
                    }}
                    video={{
                        url: mastheadBackgroundVideoUrl,
                    }}
                />
                <div className="l-grid l-grid--container t-content__components">
                    {components?.map(component => (
                        renderComponents(component, component?.slice_type)
                    ))}
                </div>
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicContent(id: { eq: $id }) {
            data {
                body {
                    ... on PrismicContentBodyAccordion {
                        items {
                            ac_content {
                                raw
                            }
                            ac_heading {
                                raw
                            }
                        }
                        primary {
                            ac_multiple
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyAddress {
                        primary {
                            ad_address {
                                raw
                            }
                            ad_map_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            ad_map_link {
                                link_type
                                uid
                                url
                            }
                            ad_name {
                                raw
                            }
                            ad_name_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyButton {
                        primary {
                            bu_style
                            bu_text {
                                raw
                            }
                            bu_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyContent {
                        primary {
                            co_content {
                                raw
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyImage {
                        primary {
                            im_caption {
                                raw
                            }
                            im_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            im_size
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyJobListing {
                        slice_type
                    }
                    ... on PrismicContentBodyLinkListing {
                        items {
                            ln_copy_link
                            ln_icon
                            ln_text {
                                raw
                            }
                            ln_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyList {
                        items {
                            li_text {
                                raw
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyLogoListing {
                        items {
                            ll_content {
                                raw
                            }
                            ll_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            ll_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodySectionHeader {
                        primary {
                            sh_heading {
                                raw
                            }
                            sh_size
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyTeamListing {
                        items {
                            tl_team_member {
                                document {
                                    ... on PrismicTeam {
                                        data {
                                            te_bio {
                                                raw
                                            }
                                            te_image {
                                                alt
                                                dimensions {
                                                    height
                                                    width
                                                }
                                                url
                                            }
                                            te_job_title {
                                                raw
                                            }
                                            te_linkedin_text {
                                                raw
                                            }
                                            te_linkedin_url {
                                                link_type
                                                type
                                                uid
                                                url
                                            }
                                            te_name {
                                                raw
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        primary {
                            tl_multiple
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyVideo {
                        primary {
                            vi_caption {
                                raw
                            }
                            vi_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            vi_size
                            vi_video {
                                html
                                provider_name
                                thumbnail_url
                                title
                            }
                        }
                        slice_type
                    }
                    ... on PrismicContentBodyAutoplayVideo {
                        primary {
                            av_size
                            av_video {
                                url
                            }
                        }
                        slice_type
                    }
                }
                ma_background_image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                ma_background_video {
                    url
                }
                ma_description {
                    raw
                }
                ma_heading {
                    raw
                }
                page_meta_description {
                    raw
                }
                page_meta_title {
                    raw
                }
                page_social_share_image {
                    url
                }
            }
        }
    } 
`;

ContentPage.propTypes = propTypes;
ContentPage.defaultProps = defaultProps;

export default withPreview(ContentPage);