import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconLinkedIn({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 512 512">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M490.7 460.5h4.8l-9.3-14c5.7 0 8.7-3.6 8.7-8v-.5c0-6-3.6-8.9-11.1-8.9h-12v31.5h4.5v-13.7h5.6l8.8 13.6zm-9.1-17.3h-5.2v-10.6h6.7c3.4 0 7.4.6 7.4 5.1-.1 5.1-4.1 5.5-8.9 5.5"/>
                <path d="M368.9 406.9h-64.1V306.4c0-24-.4-54.8-33.4-54.8-33.4 0-38.5 26.1-38.5 53.1v102.2h-64.1V200.3h61.6v28.2h.9c12.6-21.5 35.9-34.3 60.8-33.4 65 0 77 42.8 77 98.4l-.2 113.4zM96.3 172.1c-20.6 0-37.2-16.7-37.2-37.2s16.7-37.2 37.2-37.2 37.2 16.7 37.2 37.2c0 20.5-16.6 37.2-37.2 37.2m32.1 234.8H64.2V200.3h64.2v206.6zM400.9 38h-369C14.5 37.8.2 51.8 0 69.2v370.4c.2 17.5 14.5 31.4 31.9 31.2h368.9c17.5.2 31.8-13.8 32.1-31.2V69.2c-.2-17.4-14.6-31.4-32-31.2"/>
                <path d="M482.2 415c-16.3.1-29.4 13.5-29.2 29.8.1 16.3 13.5 29.4 29.8 29.2 16.3-.1 29.4-13.5 29.2-29.8-.1-16.1-13.2-29.1-29.2-29.2h-.6m.7 55.6c-14.3.2-26.8-11.1-27-25.4-.2-14.3 11.2-26.1 25.5-26.3 14.3-.2 26.1 11.2 26.3 25.5v.4c.2 13.9-10.8 25.5-24.8 25.8h-.7"/>
            </svg>
        </>
    );
}

IconLinkedIn.propTypes = propTypes;
IconLinkedIn.defaultProps = defaultProps;

export default IconLinkedIn;
