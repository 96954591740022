import React from 'react';
import PropTypes from 'prop-types';

import LinkItem from 'molecules/linkitem/LinkItem';

import './LinkListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function LinkListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-link-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-link-listing__container">
                        {listing.map(({ ln_copy_link, ln_icon, ln_text, ln_url }, index) => {
                            const copyUrl = ln_copy_link;
                            const icon = ln_icon;
                            const link = {
                                text: ln_text?.raw?.[0]?.text,
                                url: ln_url,
                            };

                            return (
                                <LinkItem
                                    className="o-link-listing__item"
                                    copyUrl={copyUrl}
                                    icon={icon}
                                    key={`{${index}}-link-item`}
                                    link={link}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

LinkListing.propTypes = propTypes;
LinkListing.defaultProps = defaultProps;

export default LinkListing;