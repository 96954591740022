import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconFacebook({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 512 512">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M512 257.6c0 127.8-93.7 233.7-216.1 252.9V332.1h59.5l11.3-73.8h-70.8v-47.9c0-20.2 9.9-39.9 41.6-39.9h32.2v-62.8s-29.2-5-57.2-5c-58.3 0-96.4 35.3-96.4 99.3v56.3h-64.8V332h64.8v178.4C93.8 491.2 0 385.3 0 257.6c0-141.4 114.6-256 256-256s256 114.6 256 256z"/>
            </svg>
        </>
    );
}

IconFacebook.propTypes = propTypes;
IconFacebook.defaultProps = defaultProps;

export default IconFacebook;
